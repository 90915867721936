import React from 'react';
import { useSpring, config } from 'react-spring';
import { withTheme } from 'styled-components';
import * as A from 'system/animated';
import { Button } from 'system';

function Burger({ theme, style, navOpen, setNavOpen, lineColor, ...rest }) {
  const navSpring = useSpring({
    config: config.stiff,
    from: { line1: 0, line2: 0, line3: 0 },
    line1: navOpen && 45,
    line2: navOpen && 300,
    line3: navOpen && -45,
    lineColor: navOpen ? theme.colors.blue : lineColor,
  });

  return (
    <A.Fixed
      right={20}
      top={[14, 23, 39]}
      zIndex={40}
      style={{
        ...style,
      }}
      {...rest}
      display={`block`}
    >
      <Button
        onClick={() => setNavOpen(!navOpen)}
        bg={`transparent`}
        width={40}
        height={41}
        px={10}
        py={12}
        display={`flex`}
        flexDirection={`column`}
        justifyContent={`space-between`}
        aria-label="Open the menu"
      >
        <A.Box
          as={`figure`}
          style={{
            transform: navSpring.line1.to(line1 => `rotate(${line1}deg)`),
            transformOrigin: `top left`,
            backgroundColor: lineColor,
            transition: `background-color 0.2s ease-out`,
          }}
          height={3}
          width={21}
          aria-hidden="true"
        />
        <A.Box
          as={`figure`}
          style={{
            transform: navSpring.line2.to(
              line2 => `translate3d(${line2}%, 0, 0)`
            ),
            backgroundColor: lineColor,
            transition: `background-color 0.2s ease-out`,
          }}
          height={3}
          width={21}
          aria-hidden="true"
        />
        <A.Box
          as={`figure`}
          style={{
            transform: navSpring.line3.to(line3 => `rotate(${line3}deg)`),
            transformOrigin: `bottom left`,
            backgroundColor: lineColor,
            transition: `background-color 0.2s ease-out`,
          }}
          height={3}
          width={21}
          aria-hidden="true"
        />
      </Button>
    </A.Fixed>
  );
}

export default withTheme(Burger);
