import css from '@styled-system/css';
import styled from 'styled-components';
import {
  background,
  border,
  color,
  flexbox,
  layout,
  opacity,
  overflow,
  position,
  space,
  system,
  typography,
  variant,
} from 'styled-system';

// Base Components
export const Box = styled(`div`)(
  css({
    position: `relative`,
    verticalAlign: `top`,
    boxSizing: `border-box`,
    display: `inline-block`,
    minWidth: 0,
  }),
  system({ transform: true, transition: true }),
  space,
  color,
  layout,
  flexbox,
  overflow,
  position
);

export const Flex = styled(Box)(flexbox);

Flex.defaultProps = {
  display: `flex`,
};

export const Text = styled(Box)(typography);
export const Heading = styled(Text)({ display: `block` });

Heading.defaultProps = {
  as: `h2`,
  mt: 0,
  mb: 0,
};

export const Link = styled(Text)({
  textDecoration: `underline`,
  color: `blue`,
});

Link.defaultProps = {
  as: `a`,
};

export const Button = styled(Text)(
  css({
    appearance: `none`,
    textAlign: `center`,
    lineHeight: `inherit`,
    textDecoration: `none`,
    cursor: `pointer`,
    fontWeight: `bold`,
    borderRadius: 4,
    border: 0,
  }),
  border
);

Button.defaultProps = {
  as: `button`,
  px: 3,
  py: 2,
  color: `inherit`,
  bg: `blue`,
};

const cards = variant({ key: 'cards' });

export const Card = styled(Box)(border, background, opacity, cards);

export const Absolute = styled(Box)(
  css({
    position: 'absolute',
  })
);

export const Fixed = styled(Box)(
  css({
    position: 'fixed',
  })
);
