import React from 'react';
import * as A from 'system/animated';
import { useSpring } from 'react-spring';

function Wipe({
  animating = false,
  bgColor = `white`,
  wipeCompleteCallback = null,
}) {
  const animationProps = useSpring({
    opacity: animating ? 1 : 0,
    config: { mass: 1, tension: 210, friction: 20, clamp: true },
    onRest: () => {
      if (animating && typeof wipeCompleteCallback === `function`) {
        wipeCompleteCallback();
      }
    },
  });
  return (
    <A.Fixed
      as={`figure`}
      width={1}
      height={`100%`}
      top={0}
      left={0}
      bg={bgColor}
      zIndex={animating ? 9 : -1}
      className={`wipe`}
      style={{
        pointerEvents: `none`,
        opacity: animationProps.opacity,
        visibility: animationProps.opacity.to(o =>
          o === 0 ? `hidden` : `visible`
        ),
      }}
    />
  );
}

export default Wipe;
