import { Link } from 'gatsby';
import React, { useEffect, useRef } from 'react';
import { useSprings } from 'react-spring';
import { withTheme } from 'styled-components';
import { Flex, Text } from 'system';
import * as A from 'system/animated';
import { Container } from 'system/layout';
import { resolveLinkByOriginalId } from 'utils/resolveLink';
import Burger from 'components/Burger';
import normalizePathname from 'utils/normalizePathname';
// import constants from 'utils/constants';

const navLinks = [
  { title: 'Know', to: `/about` },
  { title: 'Work', to: `/about` },
  { title: 'Contact', to: `/contact` },
];

const beams = [
  {
    open: 'polygon(100% -500%, -500% 120%, 120% 100%)',
    closed: 'polygon(-500% 120%, -500% 120%, 120% 100%)',
  },
];

function Nav({
  theme,
  data,
  location,
  burgerColor,
  setLogoColor,
  resetLogoColor,
  zIndex = 30,
  navOpen,
  setNavOpen,
  pageReady,
  locale,
}) {
  const { links } = data;
  const first = useRef(true);

  const rnd = useRef(Math.floor(Math.random() * beams.length));
  const items = [
    {
      delay: navOpen ? 0 : navLinks.length * 50,
      config: { mass: 1, tension: 210, friction: 35, clamp: true },
      opacity: navOpen ? 1 : 0,
      clipPath: navOpen ? beams[rnd.current].open : beams[rnd.current].closed,
      WebkitClipPath: navOpen
        ? beams[rnd.current].open
        : beams[rnd.current].closed,
      MSClipPath: navOpen ? beams[rnd.current].open : beams[rnd.current].closed,
    },
    ...links.map((link, idx) => ({
      delay: navOpen ? 600 + idx * 100 : 50 + (links.length - idx) * 30,
      config: {
        mass: 1,
        tension: 210,
        friction: navOpen ? 80 : 35,
        clamp: true,
      },
      opacity: navOpen ? 1 : 0,
      transform: `translate3d(0, ${navOpen ? 0 : idx * 10 + 10}px, 0)`,
    })),
  ];

  const springs = useSprings(items.length, items);
  const beamSpring = springs[0];
  const navLinkSprings = springs.filter((spring, idx) => idx > 0);
  const pageReadyTimeout = useRef(0);

  useEffect(() => {
    if (pageReady) {
      clearTimeout(pageReadyTimeout.current);
      // pageReadyTimeout.current = setTimeout(() => {
      setNavOpen(false);
      // }, constants.PAGE_READY_TIMEOUT);
    }
  }, [pageReady, setNavOpen]);

  useEffect(() => {
    if (navOpen) {
      setLogoColor(theme.colors.blue);
      // disableScroll();
    } else {
      if (!first.current) {
        resetLogoColor();
      }
      // enableScroll();
    }
  }, [
    navOpen,
    // disableScroll,
    // enableScroll,
    resetLogoColor,
    setLogoColor,
    theme.colors.blue,
  ]);

  useEffect(() => {
    setTimeout(() => {
      first.current = false;
    }, 100);
  }, []);

  return (
    <>
      <A.Fixed
        zIndex={zIndex + 1}
        top={0}
        left={0}
        width={1}
        height={`100vh`}
        pt={[`12.5%`, `12.5%`, `12.5%`, 0]}
        style={{
          justifyContent: `center`,
          alignItems: `center`,
          overflow: `hidden`,
          display: beamSpring.opacity.to(o => {
            return o === 0 ? `none` : `flex`;
          }),
        }}
      >
        <A.Absolute
          top={0}
          left={0}
          width={1}
          height={`100%`}
          bg={'yellow'}
          style={{
            ...beamSpring,
          }}
        />
        <Container as={`article`}>
          <Flex
            as={`article`}
            flexDirection={[`column`, `column`, `column`, `row`]}
            justifyContent={`space-between`}
            width={1}
            pl={theme.sectionPadding.x}
            pr={[0, 0, 0, locale === `fr` ? 0 : `${(1 / 6) * 100}%`]}
          >
            {links.map((item, idx) => {
              const link = resolveLinkByOriginalId(
                item.link.originalId,
                locale
              );
              const topText = item._allTopTextLocales.find(
                t => t.locale === locale
              );
              const middleText = item._allMiddleTextLocales.find(
                t => t.locale === locale
              );
              const bottomText = item._allBottomTextLocales.find(
                t => t.locale === locale
              );
              return (
                <A.Box
                  key={`navlink-${idx}`}
                  mb={bottomText ? 110 : 80}
                  style={{
                    color: theme.colors.blue,
                    textDecoration: `none`,
                    display: `block`,
                    width: `auto`,
                    flex: `none`,
                    alignSelf: `flex-start`,
                    ...navLinkSprings[idx],
                  }}
                >
                  <Link
                    to={link.url}
                    title={link.title}
                    onClick={() => {
                      if (
                        normalizePathname(link.url) ===
                        normalizePathname(location.pathname)
                      ) {
                        setNavOpen(false);
                      }
                    }}
                    css={`
                      display: block;
                      color: inherit;
                      transition: transform 0.2s ease-in;
                      :hover,
                      :focus {
                        transform: translate3d(0, 5px, 0);
                        transition: transform 0.2s ease-out;
                      }
                      :active {
                        transform: scale(0.975) translate3d(0, 3px, 0);
                        transition: transform 0.2s ease-out;
                      }
                    `}
                  >
                    {topText && (
                      <Text
                        as={`span`}
                        fontFamily={theme.fonts.body}
                        fontWeight={`bold`}
                        fontSize={[18, 20, 22]}
                        style={{
                          position: `absolute`,
                          top: `-1em`,
                          textTransform: `uppercase`,
                        }}
                      >
                        {topText.value}
                      </Text>
                    )}
                    <Text
                      as={`span`}
                      fontSize={[40, 60, 70, 70, 80]}
                      fontWeight={`bold`}
                      fontFamily={theme.fonts.headings}
                    >
                      {middleText.value.toUpperCase()}
                    </Text>

                    {bottomText && (
                      <Text
                        as={`span`}
                        fontFamily={theme.fonts.body}
                        fontWeight={`bold`}
                        fontSize={[18, 20, 22]}
                        style={{
                          position: `absolute`,
                          bottom: `-1em`,
                          right: 0,
                          textTransform: `uppercase`,
                        }}
                      >
                        {bottomText.value}
                      </Text>
                    )}
                  </Link>
                </A.Box>
              );
            })}
          </Flex>
        </Container>
      </A.Fixed>
      <Burger
        setNavOpen={setNavOpen}
        navOpen={navOpen}
        lineColor={burgerColor}
      />
    </>
  );
}

export default withTheme(Nav);
