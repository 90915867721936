import { dialog, globals, minireset } from './globalStyles';

const colors = {
  black: `#000000`,
  white: `#FFFFFF`,
  yellow: `#F5EB0A`,
  blue: `#001E45`,
  grey: `#EDF0F6`,
  greyDark: `#84898E`,
};

const fonts = {
  headings: `Heebo V4 Latin`,
  body: `IBM Plex Sans`,
};

const theme = {
  name: `default`,
  colors,
  fonts,
  sectionPadding: { x: [20, 30, 40], y: [25, 40, 50, 60, 75] },
  space: [0, 4, 6, 8, 10, 12],
  breakpoints: [`500px`, `768px`, `1024px`, `1368px`, `1920px`],
  textColor: colors.blue,
  headingColor: colors.blue,
  backgroundColor: colors.white,
  stripeColors: [],
};

export default theme;
export { globals, minireset, dialog };
