import React from 'react';
import * as A from 'system/animated';
import theme from 'theme';

const Section = React.forwardRef((props, forwardedRef) => {
  return (
    <A.Flex
      ref={forwardedRef}
      as={`section`}
      px={theme.sectionPadding.x}
      pt={theme.sectionPadding.y}
      pb={theme.sectionPadding.y}
      {...props}
    />
  );
});

const Container = React.forwardRef(({ style, ...rest }, forwardedRef) => {
  return (
    <A.Box
      ref={forwardedRef}
      m={`0 auto`}
      width={`100%`}
      maxWidth={[`100%`, `100%`, 960, 1200, 1366]}
      style={{
        display: `block`,
        outline: `none !important`,
        ...style,
      }}
      {...rest}
    />
  );
});

const Row = React.forwardRef(({ multiline = true, ...rest }, forwardedRef) => {
  return (
    <A.Flex
      ref={forwardedRef}
      flexWrap={multiline ? `wrap` : `nowrap`}
      width={1}
      ml={[-1, -2, -3, -4, -5]}
      mr={[-1, -2, -3, -4, -5]}
      mt={[-1, -2, -3, -4, -5]}
      mb={[-1, -2, -3, -4, -5]}
      {...rest}
    />
  );
});

const Column = React.forwardRef(
  ({ width = null, fill = false, ...rest }, forwardedRef) => (
    <A.Box
      ref={forwardedRef}
      width={width || `100%`}
      flex={fill ? `1 1 auto` : `none`}
      pt={[1, 2, 3, 4, 5]}
      pb={[1, 2, 3, 4, 5]}
      pl={[1, 2, 3, 4, 5]}
      pr={[1, 2, 3, 4, 5]}
      {...rest}
    />
  )
);

export { Section, Container, Row, Column };
