import dialog from '@reach/dialog/styles.css';
import minireset from 'minireset.css';

export {minireset, dialog};

export const globals = theme => `
    #___gatsby{
        width: 100%;
        position:relative;
        overflow:hidden;
    }

    #___gatsby > div {
        width: 100%;
    }

    html, body{
        width: 100%;
        height:auto;
    }

    html{
        overflow-y: scroll;
    }

    html.scrollable-image{
        overflow-y:hidden;
    }

    ::-webkit-scrollbar {
        width: 0.75rem;
      }

      ::-webkit-scrollbar-track {
        box-shadow: inset 1px 0 0px rgba(0, 0, 0, 0.1);
        background-color: ${theme.colors.grey};
      }

      ::-webkit-scrollbar-thumb {
        background-color: ${theme.colors.blue};
      }
    
    body{
        font-family:${theme.fonts.body}, Helvetica Neue, Helvetica, Arial, sans-serif;
        color:${theme.textColor};
        font-size:18px;
        font-weight:normal;
        line-height: 1.2777777778;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        text-size-adjust: 100%;
        background-color: white;
        
        @media only screen and (max-width: 767px) {
            p {
                line-height: 1.3125em;
            }
        }
        @media only screen and (max-width: 500px) {
            p {
                line-height: 1.5em;
            }
        }

        *:focus{
            outline:none !important;
        }
    }

    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      & sup {
        font-size: 0.6em;
        transform: translateY(-2px);
      }
    }

    br.mobile-only{
        display:inline-block;
        @media screen and (min-width:500px){
            display:none;
        }
    }
    
    #didomi-host{
        font-family:${theme.fonts.body}, Helvetica Neue, Helvetica, Arial, sans-serif !important;
        font-size:16px !important;
    }
    #didomi-host b:first-of-type{
        font-size:18px !important;
    }
    
    .didomi-components-button{
        font-weight:bold !important;
    }
`;
