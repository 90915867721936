import linksById from '../../linksById.json';
import linksByOriginalId from '../../linksByOriginalId.json';
function resolveLink(id) {
  return linksById[id] || `/`;
}
function resolveLinkByOriginalId(originalId, locale) {
  return linksByOriginalId[originalId][locale] || `/`;
}

export default resolveLink;
export { resolveLinkByOriginalId };
