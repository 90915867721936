import {DialogContent, DialogOverlay} from '@reach/dialog';
import LocaleToggle from 'components/LocaleToggle';
import Nav from 'components/Nav';
import TopLink from 'components/TopLink';
import Wipe from 'components/Wipe';
import {graphql, useStaticQuery} from 'gatsby';
import {GlobalProvider} from 'globals';
import useKeySequence from 'hooks/useKeySequence';
// import useSmoothScroll from 'hooks/useSmoothScroll';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import Helmet from 'react-helmet';
import {config, useSpring, useTransition} from 'react-spring';
import {createGlobalStyle, ThemeProvider} from 'styled-components';
import {Box} from 'system';
import {Absolute, Fixed} from 'system/animated';
import defaultTheme, {dialog, globals, minireset} from 'theme';
import normalizePathname from 'utils/normalizePathname';
import smoothScrollTo from 'utils/scroll/smoothScrollTo';
import create from 'zustand';
import linksByOriginalId from '../../linksByOriginalId';
// import useSmootherScroll from 'hooks/useSmootherScroll';

const GlobalStyle = createGlobalStyle`
    ${minireset}
    ${dialog}
    ${globals(defaultTheme)}
`;

const [useVideo] = create((set) => ({
	currentVideo: null,
	actions: {
		set: (id) => set(() => ({currentVideo: id})),
	},
}));

const [useNavOpen] = create((set) => ({
	navOpen: false,
	actions: {
		set: (value) => set(() => ({navOpen: value})),
	},
}));

function getLogoColor(pathname) {
	switch (true) {
		case pathname.indexOf(`/work/`) === 0:
		case pathname.indexOf(`/about`) >= 0:
			return defaultTheme.colors.white;
		case pathname === `/` || pathname === `/fr`:
		case pathname.indexOf(`/work`) >= 0:
		case pathname.indexOf(`/contact`):
		default:
			return defaultTheme.colors.blue;
	}
}

function getBackgroundColor(pathname) {
	switch (true) {
		case pathname === `/` || pathname === `/fr`:
			return defaultTheme.colors.white;
		case pathname.indexOf(`/about`) >= 0:
			return defaultTheme.colors.blue;
		case pathname.indexOf(`/work`) >= 0:
			return defaultTheme.colors.grey;
		case pathname.indexOf(`/contact`):
			return defaultTheme.colors.grey;
		default:
			return defaultTheme.colors.white;
	}
}

function Layout({location, children, data, pageContext}) {
	// fetch footer data only once to be passed to page via props
	const {locale} = pageContext;
	const [otherLanguageUrl, setOtherLanguageUrl] = useState(
		linksByOriginalId[data.page.originalId][locale === `fr` ? `en` : `fr`]
	);
	const {
		navData,
		footerData_en,
		footerData_fr,
		easterEggData,
	} = useStaticQuery(graphql`
    {
      navData: datoCmsNavigation {
        links {
          topText
          middleText
          bottomText
          _allTopTextLocales {
            locale
            value
          }
          _allMiddleTextLocales {
            locale
            value
          }
          _allBottomTextLocales {
            locale
            value
          }
          link {
            ... on DatoCmsAboutPage {
              id
              originalId
              title
              url
            }
            ... on DatoCmsWorkPage {
              id
              originalId
              title
              url
            }
            ... on DatoCmsContactPage {
              id
              originalId
              title
              url
            }
          }
        }
      }
      easterEggData: allDatoCmsAsset(filter: { url: { regex: "/-gnr.mp4/" } }) {
        edges {
          node {
            id
            width
            height
            url
          }
        }
      }
      footerData_en: datoCmsFooter(locale: { eq: "en" }) {
        callout
        contactPageParagraphNode {
          childMarkdownRemark {
            html
          }
        }
        facebookUrl
        twitterUrl
        linkedinUrl
        copyright
        ethicsUrl
        privacyLegalUrl
        gdprUrl
        accessibilityPolicyUrl
        aodaUrl
      }
      footerData_fr: datoCmsFooter(locale: { eq: "fr" }) {
        callout
        contactPageParagraphNode {
          childMarkdownRemark {
            html
          }
        }
        facebookUrl
        twitterUrl
        linkedinUrl
        copyright
        ethicsUrl
        privacyLegalUrl
        gdprUrl
        accessibilityPolicyUrl
        aodaUrl
      }
    }
  `);
	const first = useRef(true);
	const currentPathname = useRef(location.pathname);
	const easterEggVideo = easterEggData.edges[0].node;
	// const { disableScroll, enableScroll, stopScroll } = useSmoothScroll();
	// const { disableScroll, enableScroll, stopScroll } = useMemo(
	//   () => ({
	//     disableScroll: () => {},
	//     enableScroll: () => {},
	//     stopScroll: () => {},
	//   }),
	//   []
	// );
	// const scrollRerRef = useRef();
	// useSmootherScroll(scrollRerRef);

	const [wiping, setWiping] = useState(true);
	const [wipeColor, setWipeColor] = useState(
		getBackgroundColor(location.pathname)
	);

	const {navOpen, setNavOpen} = useNavOpen((state) => ({
		navOpen: state.navOpen,
		setNavOpen: state.actions.set,
	}));

	const [logoColor, setLogoColor] = useState(getLogoColor(location.pathname));
	const prevLogoColor = useRef(logoColor);

	const setPageReady = useCallback(() => {
		setWiping(false);
	}, [setWiping]);

	const [page, setPage] = useState(children);

	const completePageChange = useCallback(() => {
		// stopScroll();
		window.scrollTo({top: 0});
		prevLogoColor.current = getLogoColor(location.pathname);
		setLogoColor(getLogoColor(location.pathname));
		setPage(children);
	}, [setPage, setLogoColor, children, location.pathname]);

	const resetLogoColor = useCallback(() => {
		setLogoColor(prevLogoColor.current);
	}, [setLogoColor]);

	useEffect(() => {

		if (
			!first.current &&
			normalizePathname(currentPathname.current) ===
			normalizePathname(location.pathname)
		) {
			return;
		}
		currentPathname.current = location.pathname;
		const wc = getBackgroundColor(location.pathname);
		setWipeColor(wc);
		if (first.current) {
			setLogoColor(getLogoColor(location.pathname));
		}
		setOtherLanguageUrl(
			linksByOriginalId[data.page.originalId][locale === `fr` ? `en` : `fr`]
		);
		setWiping(first.current ? false : true);
		first.current = false;
	}, [location.pathname, locale, data]);

	const [dialogShowing, setDialogShowing] = useState(false);
	const dialogTransition = useTransition(dialogShowing, null, {
		config: {...config.gentle, clamp: true},
		from: {opacity: 0},
		enter: {opacity: 1},
		leave: {opacity: 0},
	});

	const videoSpring = useSpring({
		delay: dialogShowing ? 200 : 0,
		config: {...config.gentle, clamp: true},
		opacity: dialogShowing ? 1 : 0,
		y: dialogShowing ? 0 : 10,
	});

	const keySequence = useKeySequence([`g`, `n`, `r`]);

	useEffect(() => {
		function setDefaultLanguage(locale
		) {
			window.didomiConfig = {
				languages: {
					enabled: ['en', 'fr'],
					default: locale
				}
			};
		}

		setDefaultLanguage(locale);
	}, [locale]);

	useEffect(() => {
		if (keySequence) {
			setDialogShowing(true);
		}
	}, [keySequence, setDialogShowing]);

	useEffect(() => {
		if (navOpen) {
			prevLogoColor.current = logoColor;
		}
	}, [navOpen, logoColor]);

	useEffect(() => {
		window.smoothScrollTo = smoothScrollTo;
	}, [])

	return (
		<React.Fragment>
			<Helmet htmlAttributes={{lang: locale}}>
				<link rel="canonical" href={location.href}/>
				<link rel="alternate" href={location.href} hreflang={`${locale}-ca`}/>
				{otherLanguageUrl && otherLanguageUrl.url && (
					<link
						rel="alternate"
						href={`https://${location.host}${otherLanguageUrl.url}`}
						hreflang={`${locale === `en` ? `fr` : `en`}-ca`}
					/>
				)}
			</Helmet>
			<GlobalStyle/>
			<GlobalProvider
				value={{
					locale,
					otherLanguageUrl,
					footerData: locale === `fr` ? footerData_fr : footerData_en,
					logoColor,
					setLogoColor,
					useVideo,
					setPageReady,
					useNavOpen,
					// stopScroll,
				}}
			>
				<ThemeProvider theme={defaultTheme}>
					<React.Fragment>
						<TopLink
							location={location}
							logoColor={logoColor}
							onClick={() => {
								location.pathname === `/` && !navOpen
									? smoothScrollTo('top')
									: setNavOpen(false);
							}}
						/>
						<LocaleToggle
							locale={locale}
							otherLanguageUrl={otherLanguageUrl}
							color={logoColor}
							theme={defaultTheme}
							pathname={location.pathname}
							originalId={data.page.originalId}
						/>
						<Nav
							locale={locale}
							data={navData}
							location={location}
							setLogoColor={setLogoColor}
							resetLogoColor={resetLogoColor}
							burgerColor={logoColor}
							navOpen={navOpen}
							setNavOpen={setNavOpen}
							// disableScroll={disableScroll}
							// enableScroll={enableScroll}
							pageReady={wiping}
						/>
						<Box
							as={`main`}
							width={1}
							bg={getBackgroundColor(currentPathname.current)}
						>
							{page}
						</Box>
						<Wipe
							bgColor={wipeColor}
							animating={wiping}
							wipeCompleteCallback={completePageChange}
						/>
						{dialogTransition.map(
							({item, key, props}) =>
								item && (
									<Fixed
										as={DialogOverlay}
										key={key}
										style={{opacity: props.opacity, zIndex: 1000}}
										onDismiss={() => setDialogShowing(false)}
									>
										<Absolute
											width={[`95vw`, `95vw`, `75vw`]}
											height={0}
											style={{
												top: `50vh`,
												left: `50%`,
												opacity: videoSpring.innerOpacity,
												transform: videoSpring.y.to(
													(y) => `translate3d(-50%, ${y}px, 0)`
												),
											}}
										>
											<Box
												as={DialogContent}
												style={{
													position: `absolute`,
													width: `100%`,
													paddingTop: `56.25%`,
													margin: 0,
													transform: `translate3d(0,-50%,0)`,
													background: `transparent`,
												}}
												css={`
                                                    & iframe,
                                                    & video {
                                                        position: absolute;
                                                        top: 0;
                                                        left: 0;
                                                        width: 100%;
                                                        height: 100%;
                                                    }
												`}
											>
												<video controls autoPlay>
													<source src={easterEggVideo.url} type="video/mp4"/>
												</video>
											</Box>
										</Absolute>
									</Fixed>
								)
						)}
					</React.Fragment>
				</ThemeProvider>
			</GlobalProvider>
		</React.Fragment>
	);
}

export default Layout;
