import {
  Box,
  Flex,
  Text,
  Heading,
  Button,
  Card,
  Absolute,
  Fixed
} from 'system';
import { animated } from 'react-spring';

const AnimatedBox = animated(Box);
const AnimatedFlex = animated(Flex);
const AnimatedButton = animated(Button);
const AnimatedCard = animated(Card);
const AnimatedHeading = animated(Heading);
const AnimatedText = animated(Text);
const AnimatedAbsolute = animated(Absolute);
const AnimatedFixed = animated(Fixed);

export {
  AnimatedBox as Box,
  AnimatedFlex as Flex,
  AnimatedButton as Button,
  AnimatedCard as Card,
  AnimatedHeading as Heading,
  AnimatedText as Text,
  AnimatedAbsolute as Absolute,
  AnimatedFixed as Fixed
};
