import Logo from 'components/icons/Logo';
import { Link } from 'gatsby';
import React from 'react';
import { withTheme } from 'styled-components';
import { Fixed } from 'system';
// import Snapshot from 'components/Snapshot';

function getLocaleFromPathname(pathname) {
  return pathname.indexOf(`/fr`) >= 0 ? `fr` : `en`;
}

function TopLink({ theme, location, logoColor, onClick }) {
  const locale = getLocaleFromPathname(location.pathname);
  return (
    <Fixed top={[20, 30, 45]} left={[20, 30, 45]} zIndex={40}>
      <Link
        to={locale === `fr` ? `/fr` : `/`}
        title={`Welcome to the Jungle`}
        onClick={onClick}
      >
        <Logo fill={logoColor} zIndex={1} data-html2canvas-ignore />
      </Link>
    </Fixed>
  );
}

export default withTheme(TopLink);
