import React from 'react';
import { Fixed, Text } from 'system';
import { Link } from 'gatsby';
import css from '@styled-system/css';

function LocaleToggle({ color, locale, otherLanguageUrl }) {
  const hasOtherLanguage = otherLanguageUrl && otherLanguageUrl.url;
  return (
    <Fixed
      as={`div`}
      zIndex={40}
      top={[22, 30, 47]}
      right={60}
      css={css({
        '& a': {
          boxShadow: `0px 0px 0px 2px transparent`,
          transition: `box-shadow 0.2s ease-in`,
          ':focus, :hover': {
            outline: 'none',
            boxShadow: `0px 0px 0px 2px ${color}`,
            transition: `box-shadow 0.2s ease-out`,
          },
        },
      })}
    >
      <Text
        as={Link}
        title={hasOtherLanguage ? otherLanguageUrl.title : `Jungle`}
        to={hasOtherLanguage ? otherLanguageUrl.url : '/'}
        fontWeight={`bold`}
        color={color}
        pt={`1px`}
        pl={`3px`}
        pr={`4px`}
        pb={`2px`}
        height={[24]}
        style={{
          textDecoration: `none`,
          display: `flex`,
          alignItems: `center`,
        }}
      >
        <Text as={`span`} style={{ lineHeight: 1 }} height={[16, 16, 16]}>
          {locale === `fr` ? `EN` : `FR`}
        </Text>
      </Text>
    </Fixed>
  );
}

export default LocaleToggle;
