export default (
  id,
  container = undefined,
  offset = 0,
  location = 'top',
  callback = null
) => {
  const el = document.getElementById(id);
  if (!el) {
    console.log('no element');
    return;
  }
  if (!container) {
    container = window;
  }
  const bounds = el.getBoundingClientRect();

  const offsetName = container === window ? `pageYOffset` : `scrollTop`;

  let boundsTop = bounds[location];
  if (container !== window) {
    const containerBounds = container.getBoundingClientRect();
    boundsTop = bounds[location] - containerBounds[location];
  }

  const top = Math.floor(container[offsetName] + boundsTop + offset);
  const left = 0;
  setTimeout(() => {
    container.scroll({
      top,
      left,
      behavior: 'smooth',
    });
    if (callback) {
      callback.call(null, top, left);
    }
  }, 1);
};

export const scrollToTop = callback => {
  setTimeout(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    if (callback) {
      callback.call(null, 0, 0);
    }
  }, 1);
};
